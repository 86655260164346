.data-box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  @include font-bold();
  margin: 0px 21px;
  border-radius: 100%;
  border: 4px solid white;
  flex-direction: column;
  padding: 42px;
  height: 150px;
  width: 150px;
  text-align: center;

  &__heading {
    font-size: 4em;
  }

  &__subheading {
    font-size: 1.5em;
    text-transform: uppercase;
  }
}

.user-detail-hero-box {
  background-color: $primary;
  height: 300px;
  border-radius: 7px;
  margin-bottom: 42px;
  padding: 42px;
  width: calc(100% - 112px);
}

.monthly-summary-stat-box {
  display: flex;
  align-items: center;
  background-color: $primary;
  height: 170px;
  border-radius: 7px;
  padding: 42px;

  .data-box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    @include font-bold();
    margin: 0px 15px;
    border-radius: 100%;
    border: 4px solid white;
    flex-direction: column;
    padding: 21px;
    height: 100px;
    width: 100px;
    text-align: center;

    &__heading {
      font-size: 2em;
    }

    &__subheading {
      font-size: 1.5em;
      text-transform: uppercase;
    }
  }
}
