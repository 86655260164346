.org-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-height: 300px;
  overflow: scroll;
  border: 1px solid $light-grey;
  @include border-radius-sm();
  padding: 10px 10px 10px 10px !important;
  margin-bottom: 20px;

  .org-item {
    display: grid;
    grid-template-columns: 100px 1fr 30px;
    font-size: 1.5em;
    gap: 15px;

    div {
      display: flex;
      align-items: center;

      a {
        color: $warning;
        cursor: pointer;
        transition: 0.5s ease-in-out;

        &:hover {
          color: $dark-warning;
        }
      }
    }
  }
}
