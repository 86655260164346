.admin-link-cards-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  padding-right: 42px;
  margin: 21px 0px;

  a,
  &__item {
    background-color: $primary;
    color: white;
    @include border-radius-lg();
    padding: 21px;
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
    }

    &__title {
      margin-left: 10px;
      @include font-bold();
      font-size: 2rem;
    }
    &:hover {
      background-color: $dark-primary;
    }
  }
}
