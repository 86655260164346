.navigation-wrapper {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  width: 100vw;
  padding: 21px;
  align-items: center;
  @include shadow-md();
  margin-bottom: 42px;

  .right-nav-links {
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;

    .sign-out-link {
      margin-right: 15px;
      margin-left: 15px;
      @include font-bold();

      svg {
        font-size: 2.5em;
      }
    }
  }

  .nav-links-wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 80px 120px 145px 100px 120px;

    a {
      font-size: 1.5em;
      text-align: left;
      @include font-bold();

      .b-logo {
        svg {
          width: 42px;
        }
      }
      svg {
        width: 21px;
      }
    }
  }
}

.navigation-search-form-wrapper {
  margin-left: 30px;
}
