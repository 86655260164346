.centered-flex-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-flex-wrapper,
.flex-columns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.column-flex-end {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.center-vertically {
  display: flex;
  align-items: center;
}
