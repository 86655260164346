@mixin shadow-inset {
  -webkit-box-shadow: inset 0px 7px 11px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 7px 11px -4px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 7px 11px -4px rgba(0, 0, 0, 0.75);
}

@mixin shadow {
  -webkit-box-shadow: 0px 7px 11px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 11px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 11px -4px rgba(0, 0, 0, 0.75);
}

@mixin shadow-md {
  -webkit-box-shadow: 8px 8px 30px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 8px 8px 30px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 8px 8px 30px 3px rgba(0, 0, 0, 0.4);
}

@mixin shadow-lg {
  -webkit-box-shadow: 15px 15px 25px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 15px 15px 25px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 15px 15px 25px 3px rgba(0, 0, 0, 0.5);
}

@mixin shadow-none {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}
