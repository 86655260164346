.top-banner-wrapper {
  background-color: $primary;
  height: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    svg {
      margin-right: 10px;
    }

    @include font-bold();
    font-size: 2rem;

    a {
      background-color: white;
      padding: 3px 5px;
      @include font-bold();
      @include border-radius-md();
      margin-left: 15px;
    }
  }
}
