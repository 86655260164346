html {
  font-size: 62.5%; // 1rem is 10px
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  @include font-primary();
}

body {
  overflow-x: hidden;
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

.app {
  display: grid;
  justify-items: center;
}

a {
  color: $primary;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s ease-in-out;

  &:hover {
    color: $dark-primary;
  }
}

.spacer10 {
  margin: 10px 0px;
}
