table {
  font-size: 1.2rem;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  @include font-bold();
  font-size: 1.3rem;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;

  a {
    @include font-bold();
    font-size: 1.3rem;
  }
}

tr:nth-child(even) {
  background-color: #dddddd;
}
