.duty-entries-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  &__item {
    display: grid;
    grid-template-columns: 80px 200px 200px 300px 300px;
    gap: 15px;
    align-items: center;
    font-size: 1.5rem;
  }
}
