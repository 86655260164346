.user-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  height: 300px;
  overflow: scroll;
  border: 1px solid $light-grey;
  @include border-radius-sm();
  padding: 10px 10px 10px 10px !important;
  margin-bottom: 20px;

  .user-item {
    display: grid;
    grid-template-columns: 80px 1fr 2fr 30px;
    font-size: 1.5em;

    div {
      display: flex;
      align-items: center;

      a {
        color: $warning;
        cursor: pointer;
        transition: 0.5s ease-in-out;

        &:hover {
          color: $dark-warning;
        }
      }
    }
  }
}

.rating-detail-modal {
  &__title {
    @include font-bold();
    color: $primary;
    font-size: 2em;
    margin-bottom: 30px;
    border-bottom: 1px solid $primary;
    padding-bottom: 30px;
  }

  &__content {
    color: $dark-theme;
    font-size: 1.3em;
  }
}

.ticket-query-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  height: 70vh;
  overflow: scroll;
  border: 1px solid $light-grey;
  @include border-radius-sm();
  padding: 10px 10px 10px 10px !important;
  margin-bottom: 20px;

  .ticket-query-item {
    display: grid;
    grid-template-columns: 80px 1fr 220px 150px;
    font-size: 1.5em;

    div {
      display: flex;
      align-items: center;

      a {
        color: $primary;
        cursor: pointer;
        transition: 0.5s ease-in-out;

        &:hover {
          color: $dark-primary;
        }
      }
    }
  }
}
