.ticket-sidebar-wrapper {
  padding: 0px 42px;

  &__items-wrapper {
    &__item {
      @include border-radius-md();
      transition: 0.5s ease-in-out;
      cursor: pointer;
      width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      &:hover {
        @include shadow-md();
      }

      &__performance-data-wrapper {
        display: flex;
        align-items: center;
        @include font-bold();
        font-size: 1.2rem;

        &__activities-wrapper {
          display: flex;
          align-items: center;
        }
      }

      &__left {
        display: flex;
        align-items: center;

        img {
          @include border-radius-circle();
          width: 50px;
          height: 50px;
        }

        &__title {
          @include font-bold();
          color: $primary;
          font-size: 1.5em;
          margin-left: 15px;
          max-width: 300px;
        }
      }

      &__alert {
        font-size: 2em;
        color: $warning;
      }

      &__modal-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        color: $dark-theme;
        @include font-bold();
      }

      &__timestamp {
        font-weight: 700;
        font-size: 1em;
        display: flex;
        align-items: center;

        svg {
          margin-right: 7px;
        }
      }
    }
  }
}
