.level-grouping {
  margin: 21px 0px;

  &__title {
    margin-bottom: 15px;
  }
}

.support-tier-grid {
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-gap: 30px;
  width: 98%;
}

.support-tier-item {
  background-color: white;
  margin: 15px 0px;
  border: 1px solid $primary;
  @include border-radius-sm();
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__email {
    margin-left: 15px;

    a {
      @include font-bold();
      font-size: 1.2rem;
      color: $primary;
    }
  }

  &__phone {
    margin-left: 25px;
    width: 480px;
  }

  &__handle {
    font-size: 2rem;
    color: $primary;
  }

  &__assignment {
    font-size: 2rem;
    color: $primary;

    &:hover {
      cursor: pointer;
    }
  }

  &__trash {
    font-size: 2rem;
    color: $warning;

    &:hover {
      cursor: pointer;
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 21px;
    margin-right: 15px;
  }
}
