.form-group {
  margin-bottom: 5px;
  .text-field {
    @include base-input();
    margin-bottom: 5px;
  }

  .textarea-field {
    @include base-input();
    height: 500px;
    margin-top: 20px;
    margin-bottom: 5px;
    border: 2px solid $primary;
  }

  label {
    color: $dark-theme;
    text-transform: uppercase;
    @include font-bold();
    font-size: 1.3em;
    display: flex;
    justify-content: space-between;

    span {
      color: $dark-theme;
      text-transform: uppercase;
      @include font-bold();
    }
  }
}

.input-with-attached-icon {
  input {
    @include rounded-input();
  }

  button {
    background-color: $primary;
    border: 2px solid $primary;
    transition: 0.5s ease-in-out;
    outline: none;
    padding: 6px 20px 9px 10px;
    font-size: 2em;
    color: white;
    cursor: pointer;
    border-top-right-radius: 42px;
    border-bottom-right-radius: 42px;

    &:focus,
    &:hover,
    &:active {
      background-color: $dark-primary;
      border: 2px solid $dark-primary;
    }
  }
}

.input-with-attached-icon-and-cancel {
  display: flex;
  input {
    @include rounded-input();
  }

  .cancel-link {
    background-color: $warning;
    border: 2px solid $warning;
    transition: 0.5s ease-in-out;
    outline: none;
    padding: 6px 10px 9px 10px;
    font-size: 2em;
    color: white;
    cursor: pointer;

    &:focus,
    &:hover,
    &:active {
      background-color: $dark-warning;
      border: 2px solid $dark-warning;
    }
  }

  button {
    background-color: $primary;
    border: 2px solid $primary;
    transition: 0.5s ease-in-out;
    outline: none;
    padding: 6px 20px 9px 10px;
    font-size: 2em;
    color: white;
    cursor: pointer;
    border-top-right-radius: 42px;
    border-bottom-right-radius: 42px;

    &:focus,
    &:hover,
    &:active {
      background-color: $dark-primary;
      border: 2px solid $dark-primary;
    }
  }
}
