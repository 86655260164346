.escalation-banner {
  padding: 42px;
  background-color: $primary;
  color: white;
  margin-bottom: 21px;
  @include border-radius-md();

  .markdown-container {
    font-size: 2rem;
    font-weight: 900;
    word-break: break-word;
  }

  &__admin-wrapper {
    margin-top: 21px;
    border-top: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 21px;

    .left {
      display: flex;
      align-items: center;
      font-size: 2rem;
      font-weight: 900;

      img {
        border-radius: 100%;
        border: 1px solid white;
      }

      .content {
        @include font-bold();
        margin-right: 10px;
        font-size: 1.5rem;
        text-transform: uppercase;
      }

      .admin-email {
        margin-left: 15px;
      }
    }
  }
}
