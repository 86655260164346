.btn-primary {
  @include button();
}

.btn-warning {
  @include button();
  background-color: $warning;
  border: 1px solid $warning;

  &:hover {
    background-color: $dark-warning;
    border: 1px solid $dark-warning;
  }
}

.btn-white {
  @include button();
  background-color: white;
  border: 1px solid white;
  color: $primary;

  &:hover {
    background-color: white;
    border: 1px solid white;
    color: $primary;
  }
}

.btn-loading {
  @include button();
  background-color: $dark-primary;
  cursor: not-allowed;
}

.btn-disabled {
  @include button();
  background-color: $dark-theme;
  border: 1px solid $dark-theme;
  cursor: not-allowed;

  &:hover {
    @include shadow-none();
    background-color: $dark-theme;
    border: 1px solid $dark-theme;
    color: $background;
  }
}
