.phone-number-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: 15px;

  &__icon {
    margin-right: 10px;

    svg {
      color: $light-grey;
    }
  }
}
