.underlined-header {
  @include font-bold();
  font-size: 2em;
  width: 100%;
  padding-bottom: 21px;
  border-bottom: 2px solid $primary;
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-heading {
  font-size: 2em;
  color: $primary;
  @include font-bold();

  svg {
    margin-left: 10px;
  }
}

.split-heading-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}

.heading-md {
  font-size: 1.5em;
  color: $primary;
  @include font-bold();

  svg {
    margin-left: 10px;
  }
}
