.comments-container {
  margin-top: 50px;
  &__section-title {
    font-size: 2em;
    @include font-bold();
    color: $primary;
    margin-bottom: 30px;
  }

  &__comment-container {
    margin: 10px 0px;
    padding: 21px;
    background-color: $background;
    @include border-radius-md();
    font-size: 1.5em;

    &__metadata {
      display: flex;
      justify-content: space-between;

      &__left {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        &__delete-link {
          font-size: 0.8em;
          color: $warning;
          @include font-bold();
          transition: 0.5s ease-in-out;

          &:hover {
            color: $dark-warning;
          }
        }
      }

      &__right {
        display: flex;
        &__text {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          @include font-bold();
          text-transform: uppercase;
          text-align: right;
          padding-bottom: 4px;

          &__name {
            color: $dark-theme;
            font-size: 0.8em;
          }

          &__date {
            color: $primary;
            font-size: 0.7em;
          }
        }

        &__avatar {
          margin-left: 10px;
        }

        &__votes {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
        }
      }
    }
  }

  &__form-container {
    padding-top: 30px;
  }
}

.top-comment {
  color: $primary;
  @include font-bold();
  margin-bottom: 15px;
}
