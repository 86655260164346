:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: .8;
  --PhoneInputInternationalIconGlobe-opacity: .65;
  --PhoneInputCountrySelect-marginRight: .35em;
  --PhoneInputCountrySelectArrow-width: .3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: .45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: #00000080;
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: #0000001a;
}

.PhoneInput {
  align-items: center;
  display: flex;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
  display: block;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.PhoneInputCountrySelect {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  display: block;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  flex-direction: column;
  display: inline-flex;
}

.rdrDateDisplay {
  justify-content: space-between;
  display: flex;
}

.rdrDateDisplayItem {
  text-align: center;
  color: inherit;
  flex: 1;
  width: 0;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: .833em;
}

.rdrDateDisplayItem input {
  text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  justify-content: space-between;
  display: flex;
}

.rdrMonthAndYearPickers {
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  flex-direction: row;
  display: flex;
}

.rdrMonth {
  width: 27.667em;
}

.rdrWeekDays {
  display: flex;
}

.rdrWeekDay {
  box-sizing: inherit;
  text-align: center;
  flex-basis: 14.2857%;
}

.rdrDays {
  flex-wrap: wrap;
  display: flex;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
  outline: none;
}

.rdrDateInput .rdrWarning {
  color: red;
  font-size: 1.6em;
  line-height: 1.6em;
  position: absolute;
  top: 0;
  right: .25em;
}

.rdrDay {
  box-sizing: inherit;
  font: inherit;
  cursor: pointer;
  width: 14.2857%;
  position: relative;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
}

.rdrStaticRanges {
  flex-direction: column;
  display: flex;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: flex;
}

.rdrCalendarWrapper {
  color: #000;
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  background-color: #eff2f7;
}

.rdrDateDisplay {
  margin: .833em;
}

.rdrDateDisplayItem {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 4px;
  box-shadow: 0 1px 2px #23394236;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  color: #849095;
  background: none;
  border: 0;
  width: 100%;
  height: 2.5em;
  line-height: 2.5em;
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
}

.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  -webkit-appearance: none;
  appearance: none;
  color: #3e484f;
  cursor: pointer;
  text-align: center;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") right 8px center no-repeat;
  border: 0;
  border-radius: 4px;
  outline: 0;
  padding: 10px 30px 10px 10px;
}

.rdrMonthAndYearPickers select:hover {
  background-color: #00000012;
}

.rdrMonthPicker, .rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  background: #eff2f7;
  border: 0;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  margin: 0 .833em;
  padding: 0;
  display: block;
}

.rdrNextPrevButton:hover {
  background: #e1e7f0;
}

.rdrNextPrevButton i {
  text-align: center;
  border-style: solid;
  width: 0;
  height: 0;
  margin: auto;
  padding: 0;
  display: block;
  transform: translate(-3px);
}

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: #0000 #34495e #0000 #0000;
  transform: translate(-3px);
}

.rdrNextButton i {
  border-width: 4px 4px 4px 6px;
  border-color: #0000 #0000 #0000 #34495e;
  margin: 0 0 0 7px;
  transform: translate(3px);
}

.rdrWeekDays {
  padding: 0 .833em;
}

.rdrMonth {
  padding: 0 .833em 1.666em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  color: #849095;
  font-weight: 400;
  line-height: 2.667em;
}

.rdrDay {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  color: #1d2429;
  background: none;
  border: 0;
  height: 3em;
  padding: 0;
  line-height: 3em;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  display: flex;
  position: absolute;
  inset: 5px 0;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span:after {
  content: "";
  background: #3d91ff;
  border-radius: 2px;
  width: 18px;
  height: 2px;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%);
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #ffffffd9;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: currentColor;
  position: absolute;
  inset: 5px 0;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected {
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
  z-index: 1;
  background: #ffffff17;
  border: 0 solid;
  position: absolute;
  inset: 3px 0;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDefinedRangesWrapper {
  background: #fff;
  border-right: 1px solid #eff2f7;
  width: 226px;
  font-size: 12px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 600;
}

.rdrStaticRange {
  cursor: pointer;
  background: #fff;
  border: 0;
  border-bottom: 1px solid #eff2f7;
  outline: 0;
  padding: 0;
  display: block;
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  text-align: left;
  outline: 0;
  padding: 10px 20px;
  line-height: 18px;
  display: block;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  text-align: center;
  color: #6c767a;
  border: 1px solid #dee7eb;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  line-height: 30px;
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover {
  color: #333;
  border-color: #b4bfc4;
  outline: 0;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: "";
  background: none;
  border: 1px solid;
  border-radius: 1.333em;
  position: absolute;
  inset: -2px 0;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: #f8f8f8;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
}

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale() opacity(60%);
}

.rdrMonthName {
  text-align: left;
  color: #849095;
  padding: .833em;
  font-weight: 600;
}

body.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.sweet-overlay {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  z-index: 10000;
  background-color: #0006;
  display: none;
  position: fixed;
  inset: 0;
}

.sweet-alert {
  text-align: center;
  z-index: 99999;
  background-color: #fff;
  border-radius: 5px;
  width: 478px;
  margin-top: -200px;
  margin-left: -256px;
  padding: 17px;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
}

@media (max-width: 540px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 15px;
    right: 15px;
  }
}

.sweet-alert h2 {
  color: #575757;
  text-align: center;
  text-transform: none;
  margin: 25px 0;
  padding: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  display: block;
  position: relative;
}

.sweet-alert p {
  color: #797979;
  text-align: center;
  text-align: inherit;
  float: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: relative;
}

.sweet-alert fieldset {
  border: none;
  position: relative;
}

.sweet-alert .sa-error-container {
  webkit-transition: padding .15s, max-height .15s;
  background-color: #f1f1f1;
  max-height: 0;
  margin-left: -17px;
  margin-right: -17px;
  padding: 0 10px;
  transition: padding .15s, max-height .15s;
  overflow: hidden;
}

.sweet-alert .sa-error-container.show {
  webkit-transition: padding .2s, max-height .2s;
  max-height: 100px;
  padding: 10px 0;
  transition: padding .25s, max-height .25s;
}

.sweet-alert .sa-error-container .icon {
  color: #fff;
  text-align: center;
  background-color: #ea7d7d;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 3px;
  line-height: 24px;
  display: inline-block;
}

.sweet-alert .sa-error-container p {
  display: inline-block;
}

.sweet-alert .sa-input-error {
  opacity: 0;
  transform-origin: 50%;
  width: 20px;
  height: 20px;
  transition: all .1s;
  position: absolute;
  top: 29px;
  right: 26px;
  transform: scale(.5);
}

.sweet-alert .sa-input-error:before, .sweet-alert .sa-input-error:after {
  content: "";
  background-color: #f06e57;
  border-radius: 3px;
  width: 20px;
  height: 6px;
  margin-top: -4px;
  margin-left: -9px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.sweet-alert .sa-input-error:before {
  transform: rotate(-45deg);
}

.sweet-alert .sa-input-error:after {
  transform: rotate(45deg);
}

.sweet-alert .sa-input-error.show {
  opacity: 1;
  transform: scale(1);
}

.sweet-alert input {
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  width: 100%;
  height: 43px;
  margin-top: 10px;
  margin-bottom: 17px;
  padding: 0 12px;
  font-size: 18px;
  transition: all .3s;
  display: none;
  box-shadow: inset 0 1px 1px #0000000f;
}

.sweet-alert input:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: 0 0 3px #c4e6f5;
}

.sweet-alert input:focus::-moz-placeholder {
  opacity: .5;
  transition: opacity .3s 30ms;
}

.sweet-alert input:focus:-ms-input-placeholder {
  opacity: .5;
  transition: opacity .3s 30ms;
}

.sweet-alert input:focus::-webkit-input-placeholder {
  opacity: .5;
  transition: opacity .3s 30ms;
}

.sweet-alert input::-moz-placeholder {
  color: #bdbdbd;
}

.sweet-alert input:-ms-input-placeholder {
  color: #bdbdbd;
}

.sweet-alert input::-webkit-input-placeholder {
  color: #bdbdbd;
}

.sweet-alert.show-input input {
  display: block;
}

.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative;
}

.sweet-alert .la-ball-fall {
  opacity: 0;
  visibility: hidden;
  margin-top: 4px;
  margin-left: -27px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.sweet-alert button {
  color: #fff;
  box-shadow: none;
  -webkit-border-radius: 4px;
  cursor: pointer;
  background-color: #8cd4f5;
  border: none;
  border-radius: 5px;
  margin: 26px 5px 0;
  padding: 10px 32px;
  font-size: 17px;
  font-weight: 500;
}

.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px #80b3eb80, inset 0 0 0 1px #0000000d;
}

.sweet-alert button:hover {
  background-color: #7ecff4;
}

.sweet-alert button:active {
  background-color: #5dc2f1;
}

.sweet-alert button.cancel {
  background-color: #c1c1c1;
}

.sweet-alert button.cancel:hover {
  background-color: #b9b9b9;
}

.sweet-alert button.cancel:active {
  background-color: #a8a8a8;
}

.sweet-alert button.cancel:focus {
  box-shadow: 0 0 2px #c5cdd3cc, inset 0 0 0 1px #0000000c !important;
}

.sweet-alert button[disabled] {
  opacity: .6;
  cursor: default;
}

.sweet-alert button.confirm[disabled] {
  color: #0000;
}

.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.sweet-alert button::-moz-focus-inner {
  border: 0;
}

.sweet-alert[data-has-cancel-button="false"] button {
  box-shadow: none !important;
}

.sweet-alert[data-has-confirm-button="false"][data-has-cancel-button="false"] {
  padding-bottom: 40px;
}

.sweet-alert .sa-icon {
  box-sizing: content-box;
  border: 4px solid gray;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 20px auto;
  padding: 0;
  position: relative;
}

.sweet-alert .sa-icon.sa-error {
  border-color: #f27474;
}

.sweet-alert .sa-icon.sa-error .sa-x-mark {
  display: block;
  position: relative;
}

.sweet-alert .sa-icon.sa-error .sa-line {
  background-color: #f27474;
  border-radius: 2px;
  width: 47px;
  height: 5px;
  display: block;
  position: absolute;
  top: 37px;
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  left: 17px;
  transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  right: 16px;
  transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-warning {
  border-color: #f8bb86;
}

.sweet-alert .sa-icon.sa-warning .sa-body {
  background-color: #f8bb86;
  border-radius: 2px;
  width: 5px;
  height: 47px;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  left: 50%;
}

.sweet-alert .sa-icon.sa-warning .sa-dot {
  background-color: #f8bb86;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-left: -3px;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.sweet-alert .sa-icon.sa-info {
  border-color: #c9dae1;
}

.sweet-alert .sa-icon.sa-info:before {
  content: "";
  background-color: #c9dae1;
  border-radius: 2px;
  width: 5px;
  height: 29px;
  margin-left: -2px;
  position: absolute;
  bottom: 17px;
  left: 50%;
}

.sweet-alert .sa-icon.sa-info:after {
  content: "";
  background-color: #c9dae1;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-left: -3px;
  position: absolute;
  top: 19px;
}

.sweet-alert .sa-icon.sa-success:before, .sweet-alert .sa-icon.sa-success:after {
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 60px;
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success:before {
  transform-origin: 60px 60px;
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-success:after {
  transform-origin: 0 60px;
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-success .sa-placeholder {
  box-sizing: content-box;
  z-index: 2;
  border: 4px solid #a5dc8633;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -4px;
  left: -4px;
}

.sweet-alert .sa-icon.sa-success .sa-fix {
  z-index: 1;
  background-color: #fff;
  width: 5px;
  height: 90px;
  position: absolute;
  top: 8px;
  left: 28px;
  transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line {
  z-index: 2;
  background-color: #a5dc86;
  border-radius: 2px;
  height: 5px;
  display: block;
  position: absolute;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  top: 46px;
  left: 14px;
  transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  top: 38px;
  right: 8px;
  transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-custom {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  border-radius: 0;
}

@keyframes showSweetAlert {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.5);
  }
}

@keyframes slideFromTop {
  0% {
    top: 0%;
  }

  100% {
    top: 50%;
  }
}

@keyframes slideToTop {
  0% {
    top: 50%;
  }

  100% {
    top: 0%;
  }
}

@keyframes slideFromBottom {
  0% {
    top: 70%;
  }

  100% {
    top: 50%;
  }
}

@keyframes slideToBottom {
  0% {
    top: 50%;
  }

  100% {
    top: 70%;
  }
}

.showSweetAlert[data-animation="pop"] {
  animation: .3s showSweetAlert;
}

.showSweetAlert[data-animation="none"] {
  animation: none;
}

.showSweetAlert[data-animation="slide-from-top"] {
  animation: .3s slideFromTop;
}

.showSweetAlert[data-animation="slide-from-bottom"] {
  animation: .3s slideFromBottom;
}

.hideSweetAlert[data-animation="pop"] {
  animation: .2s hideSweetAlert;
}

.hideSweetAlert[data-animation="none"] {
  animation: none;
}

.hideSweetAlert[data-animation="slide-from-top"] {
  animation: .4s slideToTop;
}

.hideSweetAlert[data-animation="slide-from-bottom"] {
  animation: .3s slideToBottom;
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    top: 19px;
    left: 1px;
  }

  54% {
    width: 0;
    top: 19px;
    left: 1px;
  }

  70% {
    width: 50px;
    top: 37px;
    left: -8px;
  }

  84% {
    width: 17px;
    top: 48px;
    left: 21px;
  }

  100% {
    width: 25px;
    top: 45px;
    left: 14px;
  }
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    top: 54px;
    right: 46px;
  }

  65% {
    width: 0;
    top: 54px;
    right: 46px;
  }

  84% {
    width: 55px;
    top: 35px;
    right: 0;
  }

  100% {
    width: 47px;
    top: 38px;
    right: 8px;
  }
}

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

.animateSuccessTip {
  animation: .75s animateSuccessTip;
}

.animateSuccessLong {
  animation: .75s animateSuccessLong;
}

.sa-icon.sa-success.animate:after {
  animation: 4.25s ease-in rotatePlaceholder;
}

@keyframes animateErrorIcon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

.animateErrorIcon {
  animation: .5s animateErrorIcon;
}

@keyframes animateXMark {
  0% {
    opacity: 0;
    margin-top: 26px;
    transform: scale(.4);
  }

  50% {
    opacity: 0;
    margin-top: 26px;
    transform: scale(.4);
  }

  80% {
    margin-top: -6px;
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    margin-top: 0;
    transform: scale(1);
  }
}

.animateXMark {
  animation: .5s animateXMark;
}

@keyframes pulseWarning {
  0% {
    border-color: #f8d486;
  }

  100% {
    border-color: #f8bb86;
  }
}

.pulseWarning {
  animation: .75s infinite alternate pulseWarning;
}

@keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }

  100% {
    background-color: #f8bb86;
  }
}

.pulseWarningIns {
  animation: .75s infinite alternate pulseWarningIns;
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg) \9 ;
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg) \9 ;
}

.sweet-alert .sa-icon.sa-success {
  border-color: transparent\9 ;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9 ;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9 ;
}

.la-ball-fall, .la-ball-fall > div {
  box-sizing: border-box;
  position: relative;
}

.la-ball-fall {
  color: #fff;
  font-size: 0;
  display: block;
}

.la-ball-fall.la-dark {
  color: #333;
}

.la-ball-fall > div {
  float: none;
  background-color: currentColor;
  border: 0 solid;
  display: inline-block;
}

.la-ball-fall {
  width: 54px;
  height: 18px;
}

.la-ball-fall > div {
  opacity: 0;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  margin: 4px;
  animation: 1s ease-in-out infinite ball-fall;
}

.la-ball-fall > div:first-child {
  animation-delay: -200ms;
}

.la-ball-fall > div:nth-child(2) {
  animation-delay: -100ms;
}

.la-ball-fall > div:nth-child(3) {
  animation-delay: 0s;
}

.la-ball-fall.la-sm {
  width: 26px;
  height: 8px;
}

.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}

.la-ball-fall.la-2x {
  width: 108px;
  height: 36px;
}

.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.la-ball-fall.la-3x {
  width: 162px;
  height: 54px;
}

.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}

@keyframes ball-fall {
  0% {
    opacity: 0;
    transform: translateY(-145%);
  }

  10% {
    opacity: .5;
  }

  20% {
    opacity: 1;
    transform: translateY(0);
  }

  80% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: .5;
  }

  100% {
    opacity: 0;
    transform: translateY(145%);
  }
}

.form-group {
  margin-bottom: 5px;
}

.form-group .text-field {
  background-color: #0000;
  border: 2px solid #0000;
  border-bottom-color: #00c274;
  border-radius: 3px;
  outline: none;
  width: calc(100% - 24px);
  margin-bottom: 5px;
  padding: 12px;
  font-size: 1.5em;
  transition: all .5s ease-in-out;
}

.form-group .text-field:focus {
  border-bottom: 2px solid #04965b;
  box-shadow: 8px 8px 30px 3px #0006;
}

.form-group .textarea-field {
  background-color: #0000;
  border: 2px solid #00c274;
  border-radius: 3px;
  outline: none;
  width: calc(100% - 24px);
  height: 500px;
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 12px;
  font-size: 1.5em;
  transition: all .5s ease-in-out;
}

.form-group .textarea-field:focus {
  border-bottom: 2px solid #04965b;
  box-shadow: 8px 8px 30px 3px #0006;
}

.form-group label {
  color: #333;
  text-transform: uppercase;
  justify-content: space-between;
  font-family: Ubuntu, sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  display: flex;
}

.form-group label span {
  color: #333;
  text-transform: uppercase;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
}

.input-with-attached-icon input {
  color: #00c274;
  border: 2px solid #00c274;
  border-top-left-radius: 42px;
  border-bottom-left-radius: 42px;
  outline: none;
  width: 70%;
  padding: 10px 20px;
  font-size: 1.5em;
  font-weight: 900;
}

.input-with-attached-icon button {
  color: #fff;
  cursor: pointer;
  background-color: #00c274;
  border: 2px solid #00c274;
  border-top-right-radius: 42px;
  border-bottom-right-radius: 42px;
  outline: none;
  padding: 6px 20px 9px 10px;
  font-size: 2em;
  transition: all .5s ease-in-out;
}

.input-with-attached-icon button:focus, .input-with-attached-icon button:hover, .input-with-attached-icon button:active {
  background-color: #04965b;
  border: 2px solid #04965b;
}

.input-with-attached-icon-and-cancel {
  display: flex;
}

.input-with-attached-icon-and-cancel input {
  color: #00c274;
  border: 2px solid #00c274;
  border-top-left-radius: 42px;
  border-bottom-left-radius: 42px;
  outline: none;
  width: 70%;
  padding: 10px 20px;
  font-size: 1.5em;
  font-weight: 900;
}

.input-with-attached-icon-and-cancel .cancel-link {
  color: #fff;
  cursor: pointer;
  background-color: #af2e2e;
  border: 2px solid #af2e2e;
  outline: none;
  padding: 6px 10px 9px;
  font-size: 2em;
  transition: all .5s ease-in-out;
}

.input-with-attached-icon-and-cancel .cancel-link:focus, .input-with-attached-icon-and-cancel .cancel-link:hover, .input-with-attached-icon-and-cancel .cancel-link:active {
  background-color: #711c1c;
  border: 2px solid #711c1c;
}

.input-with-attached-icon-and-cancel button {
  color: #fff;
  cursor: pointer;
  background-color: #00c274;
  border: 2px solid #00c274;
  border-top-right-radius: 42px;
  border-bottom-right-radius: 42px;
  outline: none;
  padding: 6px 20px 9px 10px;
  font-size: 2em;
  transition: all .5s ease-in-out;
}

.input-with-attached-icon-and-cancel button:focus, .input-with-attached-icon-and-cancel button:hover, .input-with-attached-icon-and-cancel button:active {
  background-color: #04965b;
  border: 2px solid #04965b;
}

.button-wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px;
  display: flex;
}

.form-error-text {
  color: #af2e2e;
  text-align: center;
  height: 50px;
  font-size: 1.5em;
  font-weight: 900;
}

.search-select__multi-value__label, .search-select__input input {
  font-size: 1.3em !important;
}

.search-select__control--is-focused {
  border: 1px solid #00c274 !important;
  box-shadow: 0 0 0 1px #00c274 !important;
}

.grid-1 {
  grid-template-columns: 1fr;
  display: grid;
}

.grid-2 {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.grid-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
}

.grid-3fr-1fr {
  grid-template-columns: 3fr 1fr;
  display: grid;
}

.grid-1fr-400px {
  grid-template-columns: 1fr 400px;
  display: grid;
}

.grid-1fr-500px {
  grid-template-columns: 1fr 500px;
  display: grid;
}

.grid-1fr-600px {
  grid-template-columns: 1fr 600px;
  display: grid;
}

.grid-500-1fr {
  grid-template-columns: 500px 1fr;
  display: grid;
}

.grid-1fr-3fr {
  grid-template-columns: 1fr 3fr;
  display: grid;
}

.grid-2fr-3fr {
  grid-template-columns: 2fr 3fr;
  display: grid;
}

.grid-gap-42 {
  grid-gap: 42px;
}

.container {
  grid-template-columns: 1fr;
  padding: 0 50px;
  display: grid;
}

html {
  font-size: 62.5%;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-family: montserrat;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  -webkit-tap-highlight-color: transparent;
  font-weight: normal;
  overflow-x: hidden;
}

.app {
  justify-items: center;
  display: grid;
}

a {
  color: #00c274;
  cursor: pointer;
  text-decoration: none;
  transition: all .5s ease-in-out;
}

a:hover {
  color: #04965b;
}

.spacer10 {
  margin: 10px 0;
}

.underlined-header {
  color: #00c274;
  border-bottom: 2px solid #00c274;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  font-family: Ubuntu, sans-serif;
  font-size: 2em;
  font-weight: 700;
  display: flex;
}

.page-heading {
  color: #00c274;
  font-family: Ubuntu, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.page-heading svg {
  margin-left: 10px;
}

.split-heading-wrapper {
  justify-content: space-between;
  padding-right: 30px;
  display: flex;
}

.heading-md {
  color: #00c274;
  font-family: Ubuntu, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.heading-md svg {
  margin-left: 10px;
}

.btn-primary {
  color: #f0f0f0;
  cursor: pointer;
  background-color: #00c274;
  border: 1px solid #00c274;
  border-radius: 3px;
  outline: none;
  padding: 6px 12px;
  font-size: 1.6em;
  font-weight: 900;
  transition: all .5s ease-in-out;
}

.btn-primary:hover {
  color: #fff;
  background-color: #04965b;
  border: 1px solid #04965b;
  box-shadow: 8px 8px 30px 3px #0006;
}

.btn-primary svg {
  margin-left: 5px;
}

.btn-warning {
  color: #f0f0f0;
  cursor: pointer;
  background-color: #af2e2e;
  border: 1px solid #af2e2e;
  border-radius: 3px;
  outline: none;
  padding: 6px 12px;
  font-size: 1.6em;
  font-weight: 900;
  transition: all .5s ease-in-out;
}

.btn-warning:hover {
  color: #fff;
  background-color: #04965b;
  border: 1px solid #04965b;
  box-shadow: 8px 8px 30px 3px #0006;
}

.btn-warning svg {
  margin-left: 5px;
}

.btn-warning:hover {
  background-color: #711c1c;
  border: 1px solid #711c1c;
}

.btn-white {
  color: #00c274;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  outline: none;
  padding: 6px 12px;
  font-size: 1.6em;
  font-weight: 900;
  transition: all .5s ease-in-out;
}

.btn-white:hover {
  color: #fff;
  background-color: #04965b;
  border: 1px solid #04965b;
  box-shadow: 8px 8px 30px 3px #0006;
}

.btn-white svg {
  margin-left: 5px;
}

.btn-white:hover {
  color: #00c274;
  background-color: #fff;
  border: 1px solid #fff;
}

.btn-loading {
  color: #f0f0f0;
  cursor: pointer;
  cursor: not-allowed;
  background-color: #04965b;
  border: 1px solid #00c274;
  border-radius: 3px;
  outline: none;
  padding: 6px 12px;
  font-size: 1.6em;
  font-weight: 900;
  transition: all .5s ease-in-out;
}

.btn-loading:hover {
  color: #fff;
  background-color: #04965b;
  border: 1px solid #04965b;
  box-shadow: 8px 8px 30px 3px #0006;
}

.btn-loading svg {
  margin-left: 5px;
}

.btn-disabled {
  color: #f0f0f0;
  cursor: pointer;
  cursor: not-allowed;
  background-color: #333;
  border: 1px solid #333;
  border-radius: 3px;
  outline: none;
  padding: 6px 12px;
  font-size: 1.6em;
  font-weight: 900;
  transition: all .5s ease-in-out;
}

.btn-disabled svg {
  margin-left: 5px;
}

.btn-disabled:hover {
  color: #f0f0f0;
  background-color: #333;
  border: 1px solid #333;
  box-shadow: 0 0 #0000;
}

.loader {
  color: #00c274;
  justify-content: center;
  align-items: center;
  font-size: 4em;
  display: flex;
}

.markdown-icon svg {
  width: 24px;
  height: 15px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.markdown-container {
  grid-gap: 21px;
  overflow: overlay;
  grid-template-columns: 1fr;
  max-width: calc(100vw - 673px);
  font-size: 1.5rem;
  display: grid;
}

.markdown-container pre {
  color: #fff;
  overflow: overlay;
  background-color: #333;
  border-radius: 3px;
  width: 90%;
  padding: 21px;
  font-family: Anonymous Pro, monospace;
  font-weight: 700;
}

.markdown-container img {
  border-radius: 3px;
  max-width: 100%;
}

.chat-alert svg {
  color: #af2e2e;
}

.centered-flex-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.centered-flex-wrapper, .flex-columns {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-end {
  justify-content: flex-end;
  display: flex;
}

.column-flex-end {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.center-vertically {
  align-items: center;
  display: flex;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.2rem;
}

th {
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
  font-family: Ubuntu, sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
}

td {
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
}

td a {
  font-family: Ubuntu, sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
}

tr:nth-child(2n) {
  background-color: #ddd;
}

.homepage-wrapper {
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  display: grid;
  width: 100vw !important;
}

.homepage-wrapper .video-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.homepage-wrapper .video-container video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.homepage-wrapper .sign-in-form {
  justify-content: center;
  align-items: center;
  display: flex;
}

.homepage-wrapper .sign-in-form .content {
  width: 450px;
}

.homepage-wrapper .sign-in-form .content .headline {
  color: #00c274;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 50px;
  font-size: 3em;
  display: flex;
}

.homepage-wrapper .sign-in-form .content .headline svg {
  width: 130px;
  margin-right: 10px;
}

.homepage-wrapper .sign-in-form .content .subtitle {
  text-align: center;
  color: #333;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 50px;
  font-family: Ubuntu, sans-serif;
  font-size: 1.8em;
  font-weight: 900;
}

.ticket-sidebar-wrapper {
  padding: 0 42px;
}

.ticket-sidebar-wrapper__items-wrapper__item {
  cursor: pointer;
  border-radius: 7px;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  padding: 20px;
  transition: all .5s ease-in-out;
  display: flex;
}

.ticket-sidebar-wrapper__items-wrapper__item:hover {
  box-shadow: 8px 8px 30px 3px #0006;
}

.ticket-sidebar-wrapper__items-wrapper__item__performance-data-wrapper {
  align-items: center;
  font-family: Ubuntu, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
}

.ticket-sidebar-wrapper__items-wrapper__item__performance-data-wrapper__activities-wrapper, .ticket-sidebar-wrapper__items-wrapper__item__left {
  align-items: center;
  display: flex;
}

.ticket-sidebar-wrapper__items-wrapper__item__left img {
  border-radius: 100px;
  width: 50px;
  height: 50px;
}

.ticket-sidebar-wrapper__items-wrapper__item__left__title {
  color: #00c274;
  max-width: 300px;
  margin-left: 15px;
  font-family: Ubuntu, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.ticket-sidebar-wrapper__items-wrapper__item__alert {
  color: #af2e2e;
  font-size: 2em;
}

.ticket-sidebar-wrapper__items-wrapper__item__modal-link {
  color: #333;
  justify-content: center;
  align-items: center;
  font-family: Ubuntu, sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  display: flex;
}

.ticket-sidebar-wrapper__items-wrapper__item__timestamp {
  align-items: center;
  font-size: 1em;
  font-weight: 700;
  display: flex;
}

.ticket-sidebar-wrapper__items-wrapper__item__timestamp svg {
  margin-right: 7px;
}

.ticket-detail {
  border-radius: 7px;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px;
  padding: 42px;
  display: flex;
  box-shadow: 8px 8px 30px 3px #0006;
}

.ticket-detail__top__heading {
  justify-content: space-between;
  margin-bottom: 21px;
  display: flex;
}

.ticket-detail__top__heading__thumbnail-and-rating {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.ticket-detail__top__heading__thumbnail-and-rating__counter {
  color: #00c274;
  margin: 10px 0;
  font-family: Ubuntu, sans-serif;
  font-size: 1.8rem;
  font-weight: 900;
}

.ticket-detail__top__heading__thumbnail-and-rating__rating {
  font-size: 1.2em;
  font-weight: 900;
}

.ticket-detail__top__heading__left-column {
  grid-gap: 3px;
  grid-template-columns: 1fr;
  display: grid;
}

.ticket-detail__top__heading__left-column__title {
  color: #00c274;
  overflow: overlay;
  white-space: nowrap;
  max-width: 100%;
  font-family: Ubuntu, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.ticket-detail__top__heading__left-column__date {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: Ubuntu, sans-serif;
  font-size: 1.1em;
  font-weight: 900;
}

.ticket-detail__top__heading__left-column__user {
  color: #00c274;
  font-weight: 900;
}

.ticket-detail__bottom {
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.ticket-detail__bottom button {
  margin-left: 21px;
}

.comments-container {
  margin-top: 50px;
}

.comments-container__section-title {
  color: #00c274;
  margin-bottom: 30px;
  font-family: Ubuntu, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.comments-container__comment-container {
  background-color: #f0f0f0;
  border-radius: 7px;
  margin: 10px 0;
  padding: 21px;
  font-size: 1.5em;
}

.comments-container__comment-container__metadata {
  justify-content: space-between;
  display: flex;
}

.comments-container__comment-container__metadata__left {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.comments-container__comment-container__metadata__left__delete-link {
  color: #af2e2e;
  font-family: Ubuntu, sans-serif;
  font-size: .8em;
  font-weight: 700;
  transition: all .5s ease-in-out;
}

.comments-container__comment-container__metadata__left__delete-link:hover {
  color: #711c1c;
}

.comments-container__comment-container__metadata__right {
  display: flex;
}

.comments-container__comment-container__metadata__right__text {
  text-transform: uppercase;
  text-align: right;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  display: flex;
}

.comments-container__comment-container__metadata__right__text__name {
  color: #333;
  font-size: .8em;
}

.comments-container__comment-container__metadata__right__text__date {
  color: #00c274;
  font-size: .7em;
}

.comments-container__comment-container__metadata__right__avatar {
  margin-left: 10px;
}

.comments-container__comment-container__metadata__right__votes {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  display: flex;
}

.comments-container__form-container {
  padding-top: 30px;
}

.top-comment {
  color: #00c274;
  margin-bottom: 15px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
}

.escalation-banner {
  color: #fff;
  background-color: #00c274;
  border-radius: 7px;
  margin-bottom: 21px;
  padding: 42px;
}

.escalation-banner .markdown-container {
  word-break: break-word;
  font-size: 2rem;
  font-weight: 900;
}

.escalation-banner__admin-wrapper {
  border-top: 2px solid #fff;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
  padding-top: 21px;
  display: flex;
}

.escalation-banner__admin-wrapper .left {
  align-items: center;
  font-size: 2rem;
  font-weight: 900;
  display: flex;
}

.escalation-banner__admin-wrapper .left img {
  border: 1px solid #fff;
  border-radius: 100%;
}

.escalation-banner__admin-wrapper .left .content {
  text-transform: uppercase;
  margin-right: 10px;
  font-family: Ubuntu, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}

.escalation-banner__admin-wrapper .left .admin-email {
  margin-left: 15px;
}

.chat-form {
  width: 100%;
}

.chat-form__form-group {
  display: flex;
}

.chat-message-wrapper {
  grid-gap: 15px;
  grid-template-columns: 50px 1fr;
  margin: 15px 0;
  display: grid;
}

.chat-message-wrapper__body__name {
  margin-bottom: 7px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
}

.chat-message-wrapper__body__name__timestamp {
  color: #00c274;
  margin-left: 15px;
  font-size: .9em;
  font-style: italic;
}

.sidebar-scroll-container {
  border: 1px solid #0003;
  border-radius: 7px;
  overflow: scroll;
  height: calc(100vh - 300px) !important;
}

.user-list {
  grid-gap: 20px;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  grid-template-columns: 1fr;
  height: 300px;
  margin-bottom: 20px;
  display: grid;
  overflow: scroll;
  padding: 10px !important;
}

.user-list .user-item {
  grid-template-columns: 80px 1fr 2fr 30px;
  font-size: 1.5em;
  display: grid;
}

.user-list .user-item div {
  align-items: center;
  display: flex;
}

.user-list .user-item div a {
  color: #af2e2e;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.user-list .user-item div a:hover {
  color: #711c1c;
}

.rating-detail-modal__title {
  color: #00c274;
  border-bottom: 1px solid #00c274;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-family: Ubuntu, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.rating-detail-modal__content {
  color: #333;
  font-size: 1.3em;
}

.ticket-query-list {
  grid-gap: 20px;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  grid-template-columns: 1fr;
  height: 70vh;
  margin-bottom: 20px;
  display: grid;
  overflow: scroll;
  padding: 10px !important;
}

.ticket-query-list .ticket-query-item {
  grid-template-columns: 80px 1fr 220px 150px;
  font-size: 1.5em;
  display: grid;
}

.ticket-query-list .ticket-query-item div {
  align-items: center;
  display: flex;
}

.ticket-query-list .ticket-query-item div a {
  color: #00c274;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.ticket-query-list .ticket-query-item div a:hover {
  color: #04965b;
}

.chat-admin-links {
  font-size: 1.5em;
  font-weight: 900;
}

.chat-admin-links a {
  margin-right: 10px;
}

.chat-admin-links__warning {
  color: #af2e2e;
}

.chat-admin-links__warning:hover {
  color: #711c1c;
}

.admin-link-cards-wrapper {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  margin: 21px 0;
  padding-right: 42px;
  display: grid;
}

.admin-link-cards-wrapper a, .admin-link-cards-wrapper__item {
  color: #fff;
  background-color: #00c274;
  border-radius: 10px;
  align-items: center;
  padding: 21px;
  display: flex;
}

.admin-link-cards-wrapper a svg, .admin-link-cards-wrapper__item svg {
  font-size: 2rem;
}

.admin-link-cards-wrapper a__title, .admin-link-cards-wrapper__item__title {
  margin-left: 10px;
  font-family: Ubuntu, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.admin-link-cards-wrapper a:hover, .admin-link-cards-wrapper__item:hover {
  background-color: #04965b;
}

.level-grouping {
  margin: 21px 0;
}

.level-grouping__title {
  margin-bottom: 15px;
}

.support-tier-grid {
  grid-gap: 30px;
  grid-template-columns: 1fr 400px;
  width: 98%;
  display: grid;
}

.support-tier-item {
  background-color: #fff;
  border: 1px solid #00c274;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  display: flex;
}

.support-tier-item__email {
  margin-left: 15px;
}

.support-tier-item__email a {
  color: #00c274;
  font-family: Ubuntu, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
}

.support-tier-item__phone {
  width: 480px;
  margin-left: 25px;
}

.support-tier-item__handle, .support-tier-item__assignment {
  color: #00c274;
  font-size: 2rem;
}

.support-tier-item__assignment:hover {
  cursor: pointer;
}

.support-tier-item__trash {
  color: #af2e2e;
  font-size: 2rem;
}

.support-tier-item__trash:hover {
  cursor: pointer;
}

.support-tier-item__actions {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 21px;
  margin-right: 15px;
  display: grid;
}

.org-list {
  grid-gap: 20px;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  grid-template-columns: 1fr;
  max-height: 300px;
  margin-bottom: 20px;
  display: grid;
  overflow: scroll;
  padding: 10px !important;
}

.org-list .org-item {
  grid-template-columns: 100px 1fr 30px;
  gap: 15px;
  font-size: 1.5em;
  display: grid;
}

.org-list .org-item div {
  align-items: center;
  display: flex;
}

.org-list .org-item div a {
  color: #af2e2e;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.org-list .org-item div a:hover {
  color: #711c1c;
}

.phone-number-wrapper {
  align-items: center;
  margin-left: 15px;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
}

.phone-number-wrapper__icon {
  margin-right: 10px;
}

.phone-number-wrapper__icon svg {
  color: #bfbfbf;
}

.navigation-wrapper {
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  width: 100vw;
  margin-bottom: 42px;
  padding: 21px;
  display: grid;
  box-shadow: 8px 8px 30px 3px #0006;
}

.navigation-wrapper .right-nav-links {
  text-align: right;
  align-items: center;
  width: 100%;
  display: flex;
}

.navigation-wrapper .right-nav-links .sign-out-link {
  margin-left: 15px;
  margin-right: 15px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
}

.navigation-wrapper .right-nav-links .sign-out-link svg {
  font-size: 2.5em;
}

.navigation-wrapper .nav-links-wrapper {
  grid-template-columns: 80px 120px 145px 100px 120px;
  align-items: center;
  display: grid;
}

.navigation-wrapper .nav-links-wrapper a {
  text-align: left;
  font-family: Ubuntu, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.navigation-wrapper .nav-links-wrapper a .b-logo svg {
  width: 42px;
}

.navigation-wrapper .nav-links-wrapper a svg {
  width: 21px;
}

.navigation-search-form-wrapper {
  margin-left: 30px;
}

.top-banner-wrapper {
  color: #fff;
  background-color: #00c274;
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex;
}

.top-banner-wrapper__content {
  font-family: Ubuntu, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.top-banner-wrapper__content svg {
  margin-right: 10px;
}

.top-banner-wrapper__content a {
  background-color: #fff;
  border-radius: 7px;
  margin-left: 15px;
  padding: 3px 5px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
}

.request-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.request-wrapper__card {
  border-radius: 7px;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  padding: 42px;
  display: flex;
  box-shadow: 8px 8px 30px 3px #0006;
}

.request-wrapper__card__rating_wrapper {
  justify-content: center;
  display: flex;
}

.rating-star-empty svg {
  color: #bfbfbf;
  font-size: 4.2em;
}

.rating-star-full-poor svg {
  color: #af2e2e;
  font-size: 4.2em;
}

.rating-star-full-good svg {
  color: #333;
  font-size: 4.2em;
}

.rating-star-full-superior svg {
  color: #00c274;
  font-size: 4.2em;
}

.rdrStaticRanges button {
  color: #00c274 !important;
}

.duty-entries-wrapper {
  grid-template-columns: 1fr;
  gap: 15px;
  display: grid;
}

.duty-entries-wrapper__item {
  grid-template-columns: 80px 200px 200px 300px 300px;
  align-items: center;
  gap: 15px;
  font-size: 1.5rem;
  display: grid;
}

.data-box {
  color: #fff;
  text-align: center;
  border: 4px solid #fff;
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: 0 21px;
  padding: 42px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  display: flex;
}

.data-box__heading {
  font-size: 4em;
}

.data-box__subheading {
  text-transform: uppercase;
  font-size: 1.5em;
}

.user-detail-hero-box {
  background-color: #00c274;
  border-radius: 7px;
  width: calc(100% - 112px);
  height: 300px;
  margin-bottom: 42px;
  padding: 42px;
}

.monthly-summary-stat-box {
  background-color: #00c274;
  border-radius: 7px;
  align-items: center;
  height: 170px;
  padding: 42px;
  display: flex;
}

.monthly-summary-stat-box .data-box {
  color: #fff;
  text-align: center;
  border: 4px solid #fff;
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 15px;
  padding: 21px;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  display: flex;
}

.monthly-summary-stat-box .data-box__heading {
  font-size: 2em;
}

.monthly-summary-stat-box .data-box__subheading {
  text-transform: uppercase;
  font-size: 1.5em;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navigation-search-form-wrapper {
    display: none !important;
  }

  .navigation-wrapper .right-nav-links {
    justify-content: flex-end !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .navigation-search-form-wrapper {
    display: none !important;
  }

  .navigation-wrapper .right-nav-links {
    justify-content: flex-end !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .navigation-search-form-wrapper {
    display: none !important;
  }

  .navigation-wrapper .right-nav-links {
    justify-content: flex-end !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .navigation-search-form-wrapper {
    display: none !important;
  }

  .navigation-wrapper .right-nav-links {
    justify-content: flex-end !important;
  }
}

/*# sourceMappingURL=index.676e3ed9.css.map */
