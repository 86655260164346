.request-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &__card {
    padding: 42px 42px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include shadow-md();
    @include border-radius-md();
    width: 500px;

    &__rating_wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.rating-star-empty {
  svg {
    font-size: 4.2em;
    color: $light-grey;
  }
}

.rating-star-full-poor {
  svg {
    font-size: 4.2em;
    color: $warning;
  }
}

.rating-star-full-good {
  svg {
    font-size: 4.2em;
    color: $dark-theme;
  }
}

.rating-star-full-superior {
  svg {
    font-size: 4.2em;
    color: $primary;
  }
}
